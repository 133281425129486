/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";

class NavComponent extends Component {
  componentDidMount() {
    this.addKeyupListeners();
    this.addScrollListeners();
  }

  //

  addKeyupListeners = () => {
    window.addEventListener(`keyup`, e => {
      switch (e.keyCode) {
        case 27:
          this.close();

          break;

        default:
          break;
      }
    });
  };

  addScrollListeners = () => {
    document.addEventListener(`scroll`, () => {
      this.close();
    });
  };

  //

  checkout = () => {
    let cartString = ``;

    this.props.appContext.cart.forEach(cartItem => {
      let prefix = `,`;

      if (cartString === ``) {
        prefix = `/`;
      }

      cartString = `${cartString}${prefix}${cartItem.variantId}:${cartItem.quantity}`;
    });

    const win = window.open(
      `https://${process.env.GATSBY_SHOPIFY_STORE}.myshopify.com/cart${cartString}`,
      `_blank`
    );

    win.focus();
  };

  close = () => {
    this.props.appContext.setCartActive(false);
  };

  removeItem = index => {
    const { cart } = this.props.appContext;

    cart.splice(index, 1);

    this.props.appContext.setCart(cart);
  };

  //

  render() {
    let cartTotal = 0;

    this.props.appContext.cart.forEach(cartItem => {
      cartTotal += parseFloat(cartItem.price) * cartItem.quantity;
    });

    return (
      <div
        className={`nav ${
          this.props.appContext.cartActive ? `cart-active` : ``
        } w-screen h-screen fixed flex items-center justify-center z-50`}
      >
        <div
          role="presentation"
          className="nav__background w-screen h-screen fixed top-0 right-0 bottom-0 left-0 bg-black z-10"
          onClick={() => {
            this.props.appContext.setCartActive(false);
          }}
        ></div>

        <div className="nav__cart h-full absolute z-20 pt-12 px-4 bg-sun-yellow overflow-y-auto overflow-x-hidden">
          <h3 className="nav__cart__title pb-4 f3">Cart</h3>

          {(this.props.appContext.cart.length && (
            <>
              <ul>
                {this.props.appContext.cart.map((cartItem, index) => {
                  const productKey = `${cartItem.handle}-${index}`;

                  return (
                    <li
                      key={productKey}
                      className="nav__cart__item animation-appear-from-left relative py-4 flex items-stretch"
                      style={{
                        animationDelay: `${index + 2}00ms`,
                        transitionDelay: `${index + 2}00ms`
                      }}
                    >
                      <button
                        type="button"
                        className="nav__cart__item__remove absolute top-0 right-0 z-10 flex items-center justify-center"
                        onClick={() => this.removeItem(index)}
                      >
                        ✖
                      </button>

                      {cartItem.image && (
                        <Link
                          to={`/products/${cartItem.handle}`}
                          className="nav__cart__item__image relative block mr-6 overflow-hidden"
                        >
                          <img src={cartItem.image} alt={cartItem.handle} />
                        </Link>
                      )}

                      <Link
                        to={`/products/${cartItem.handle}`}
                        className="nav__cart__item__details flex flex-col items-start justify-center"
                      >
                        <h3 className="f4 mb-4">{cartItem.name}</h3>
                        <p className="b1">${cartItem.price}</p>
                      </Link>

                      <div className="nav__cart__item__price relative flex items-center justify-center">
                        x{cartItem.quantity}
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className="relative flex justify-between mt-4">
                <h4 className="f4">Total:</h4>
                <h4 className="f4">${cartTotal.toFixed(2)}</h4>
              </div>

              <button
                type="button"
                onClick={this.checkout}
                className="w-full relative block mt-6 mb-12 py-3 f4 bg-black font-bold text-sun-yellow"
              >
                Checkout
              </button>
            </>
          )) || (
            <h4
              className="animation-appear b1"
              style={{ animationDelay: `50ms` }}
            >
              <br />
              <span>Your cart is empty. </span>

              <Link
                to="/products"
                className="underline"
                onClick={() => this.props.appContext.setCartActive(false)}
              >
                Fill it
              </Link>

              <span> to have good luck for seven years.</span>
            </h4>
          )}
        </div>
      </div>
    );
  }
}

const Nav = () => (
  <AppContext.Consumer>
    {appContext => <NavComponent appContext={appContext} />}
  </AppContext.Consumer>
);

export default Nav;
