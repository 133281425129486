import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import { getWindowDimensions } from "~utils/dom";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    cursorCenterDeltaX: 0, // 0 at center, -0.5/0.5 at edges
    cursorCenterDeltaY: 0, // 0 at center, -0.5/0.5 at edges
    cursorPositionX: 0,
    cursorPositionY: 0,
    device: ``,
    documentHeight: 0,
    scrollTop: 0,
    windowWidth: 0,
    windowHeight: 0,

    //

    cart: [],
    cartActive: false,
    headerCollapsed: false,
    loadedObjects: [],
    menuActive: false,
    navText: ``
  };

  mobileWidth = 768;

  tabletWidth = 1024;

  //
  // React lifecycle

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`%c Fluff is: running `, `background: #f2e032; color: #000000`);

    setTimeout(() => {
      this.updateWindowDimensions();
    });

    document.removeEventListener(`mousemove`, this.updateCursorPosition, false);
    document.removeEventListener(`resize`, this.updateWindowDimensions, false);
    document.removeEventListener(`scroll`, this.updateScrollTop, false);

    document.addEventListener(`mousemove`, this.updateCursorPosition, false);
    document.addEventListener(`scroll`, this.updateScrollTop, false);

    window.addEventListener(`resize`, this.updateWindowDimensions, false);

    //
    // TODO: Add live quantity manager
    //

    // We don't currently have a live quantity tracker because the inventory is only ever updated once on each build.
    // To achieve this, we should ping the Shopify API here, everytime the client loads the AppContext.
    // We can then use these inventory values across the site.
  }

  componentWillUnmount() {
    document.removeEventListener(`mousemove`, this.updateCursorPosition, false);
    document.removeEventListener(`resize`, this.updateWindowDimensions, false);
    document.removeEventListener(`scroll`, this.updateScrollTop, false);
  }

  //
  // listeners

  updateCursorPosition = event => {
    this.setState(prevState => ({
      cursorCenterDeltaX: -(0.5 - event.pageX / prevState.windowWidth),
      cursorPositionX: event.pageX,
      cursorCenterDeltaY: -(
        0.5 -
        (event.pageY - window.pageYOffset) / prevState.windowHeight
      ),
      cursorPositionY: event.pageY - window.pageYOffset
    }));
  };

  updateScrollTop = e => {
    this.setState({
      scrollTop: e.target.scrollingElement.scrollTop
    });
  };

  updateWindowDimensions = () => {
    let device = `desktop`;

    if (
      window.matchMedia(
        `(min-width: ${this.mobileWidth}px) and (max-width: ${this.tabletWidth}px)`
      ).matches
    ) {
      device = `tablet`;
    } else if (
      window.matchMedia(`(max-width: ${this.mobileWidth - 1}px)`).matches
    ) {
      device = `mobile`;
    }

    this.setState({
      device,
      documentHeight: document.documentElement.offsetHeight,
      windowWidth: getWindowDimensions().width,
      windowHeight: getWindowDimensions().height
    });
  };

  //
  // API

  setCart = cart => {
    this.setState({
      cart
    });
  };

  setCartActive = cartActive => {
    this.setState({
      cartActive
    });
  };

  setHeaderCollapsed = headerCollapsed => {
    this.setState({
      headerCollapsed
    });
  };

  setLoadedObjects = loadedObjects => {
    this.setState({
      loadedObjects
    });
  };

  setMenuActive = menuActive => {
    this.setState({
      menuActive
    });
  };

  setNavText = navText => {
    this.setState({
      navText
    });
  };

  //
  // render/wrapper

  render() {
    return (
      <AppContext.Provider
        value={{
          cursorCenterDeltaX: this.state.cursorCenterDeltaX,
          cursorCenterDeltaY: this.state.cursorCenterDeltaY,
          cursorPositionX: this.state.cursorPositionX,
          cursorPositionY: this.state.cursorPositionY,
          device: this.state.device,
          documentHeight: this.state.documentHeight,
          scrollTop: this.state.scrollTop,
          windowWidth: this.state.windowWidth,
          windowHeight: this.state.windowHeight,
          //
          setCart: this.setCart,
          setCartActive: this.setCartActive,
          setHeaderCollapsed: this.setHeaderCollapsed,
          setLoadedObjects: this.setLoadedObjects,
          setMenuActive: this.setMenuActive,
          setNavText: this.setNavText,
          //
          cart: this.state.cart,
          cartActive: this.state.cartActive,
          headerCollapsed: this.state.headerCollapsed,
          loadedObjects: this.state.loadedObjects,
          menuActive: this.state.menuActive,
          navText: this.state.navText
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default AppProvider;
